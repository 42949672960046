
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { hideModal } from "@/presentation/helper/Dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import SwalNotification from "../plugins/SwalNotification";
import { VaccinationListModel } from "@/domain/vaccination/vaccinationList/model/VaccinationListModel";
import { CUSTOM_ERROR_MESSAGES, SWAL_MESSAGES } from "@/domain/Constant";
import { AnimalVaccineAddModel } from "@/domain/animal/animalVaccine/animalVaccineAdd/model/AnimalVaccineAddModel";

export default defineComponent({
  name: "new-target-modal",
  components: { Field, Form, ErrorMessage },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const vaccinationList = ref<VaccinationListModel[]>([]);
    const originalVaccinationList = ref<VaccinationListModel[]>([]);

    const swalNotification = new SwalNotification();

    const animalVaccinateModel = ref<AnimalVaccineAddModel>({
      vaccine: 0,
      amount: 0,
    });

    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const vaccinationController =
      store.state.ControllersModule.vaccinationController;
    const animalController = store.state.ControllersModule.animalController;

    if (!animalController) {
      throw new Error("AnimalController is not available in Vuex store.");
    }
    if (!vaccinationController) {
      throw new Error("VaccinationController is not available in Vuex store.");
    }

    //Create form validation object
    const form = Yup.object().shape({
      vaccine: Yup.string()
        .test(
          "isRequired",
          t(CUSTOM_ERROR_MESSAGES.required),
          function (value) {
            return value !== "0" && value !== null;
          }
        )
        .label("Vaccine"),
      amount: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("Amount"),
      description: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("Description"),
      date: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("Date"),
    });

    const getVaccinationList = () => {
      const vaccinationListModel: VaccinationListModel = {
        vaccine: {},
        page: 1,
        pageSize: pageSize.value,
      };

      vaccinationController
        .vaccinationList(vaccinationListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((vaccination) => {
              totalItems.value = vaccination.pagination.total;
              currentPage.value = vaccination.pagination.page;
              pageCount.value = vaccination.pagination.pageCount;
              vaccinationList.value.push(vaccination);
              originalVaccinationList.value.push(vaccination);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const submit = () => {
      loading.value = true;
      animalVaccinateModel.value.animal = Number(
        store.getters.selectedAnimalId
      );

      animalController
        .addAnimalVaccine(animalVaccinateModel.value)
        .then((response) => {
          loading.value = false;
          if (response.getValue()) {
            store.dispatch("newVaccine", store.getters.newVaccine + 1 );
            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS_ADD_ANIMAL_VACCINE),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              hideModal(newTargetModalRef.value);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(async () => {
      getVaccinationList();
    });

    return {
      animalVaccinateModel,
      submit,
      loading,
      formRef,
      newTargetModalRef,
      vaccinationList,
      form,
    };
  },
});
