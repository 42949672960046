<template>
  <!--begin::Modal - New Target-->
  <div
    class="modal fade"
    id="vaccinate_modal"
    ref="newTargetModalRef"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-800px">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body px-10 px-lg-15 pt-0 pb-15">
          <!--begin:Form-->
          <Form
            id="vaccinate_modal_form"
            novalidate="novalidate"
            @submit="submit"
            :validation-schema="form"
          >
            <!--begin::Heading-->
            <div class="mb-13 text-center">
              <!--begin::Title-->
              <h1 class="mb-3">{{ $t("menuPages.vaccinate.pageName") }}</h1>
              <!--end::Title-->

              <!--begin::Description-->
              <div class="text-gray-400 fw-bold fs-5">
                {{ $t("menuPages.vaccinate.pageDescription") }}
              </div>
              <!--end::Description-->
            </div>
            <!--end::Heading-->

            <!--begin::Input group-->
            <div class="row g-9 mb-8">
              <!--begin::Col-->
              <div class="col-md-4 fv-row">
                <label class="required fs-6 fw-bold mb-2">{{
                  $t("menuPages.vaccinate.vaccine")
                }}</label>

                <Field
                  name="vaccine"
                  data-control="select1"
                  data-hide-search="true"
                  data-placeholder='{{ $t("menuPages.vaccinate.selectVaccine") }}'
                  class="form-select form-select-solid"
                  as="select"
                  v-model="animalVaccinateModel.vaccine"
                >
                  <option value="0" selected>
                    {{ $t("menuPages.vaccinate.selectVaccine") }}
                  </option>
                  <option
                    :label="vaccination.vaccine.name"
                    :value="vaccination.vaccine.id"
                    v-for="vaccination in vaccinationList"
                    :key="vaccination.vaccine.id"
                  >
                    {{ vaccination.vaccine.name }}
                  </option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="vaccine" />
                  </div>
                </div>
              </div>
              <!--end::Col-->

              <!--begin::Col-->
              <div class="col-md-4 fv-row">
                <!--begin::Label-->
                <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                  <span class="required">{{
                    $t("menuPages.vaccinate.amount")
                  }}</span>
                </label>
                <!--end::Label-->

                <Field
                  class="form-control form-control-solid"
                  v-model="animalVaccinateModel.amount"
                  :placeholder="$t('menuPages.vaccinate.enterAmount')"
                  name="amount"
                  type="number"
                  min="1"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="amount" />
                  </div>
                </div>
              </div>
              <!--end::Col-->

              <!--begin::Col-->
              <div class="col-md-4 fv-row">
                <label class="required fs-6 fw-bold mb-2">{{
                  $t("menuPages.vaccinate.date")
                }}</label>

                <!--begin::Input-->
                <div class="position-relative align-items-center">
                  <!--begin::Datepicker-->
                  <Field
                    v-model="animalVaccinateModel.completedDate"
                    type="date"
                    :max="new Date().toISOString().split('T')[0]"
                    name="date"
                    class="form-control form-control-lg form-control-solid"
                    :placeholder="$t('menuPages.vaccinate.enterDate')"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="date" />
                    </div>
                  </div>
                  <!--end::Datepicker-->
                </div>
                <!--end::Input-->
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="d-flex flex-column mb-8 fv-row">
              <!--begin::Label-->
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span class="required">{{
                  $t("menuPages.vaccinate.description")
                }}</span>
              </label>
              <!--end::Label-->

              <Field
                class="form-control form-control-solid"
                v-model="animalVaccinateModel.description"
                :placeholder="$t('menuPages.vaccinate.enterDescription')"
                name="description"
                type="text"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="description" />
                </div>
              </div>
            </div>
            <!--end::Input group-->

            <!--begin::Actions-->
            <div class="text-center">
              <!--begin::Button-->
              <button
                :data-kt-indicator="loading ? 'on' : null"
                class="btn btn-lg btn-primary"
                type="submit"
              >
                <span v-if="!loading" class="indicator-label">
                  {{ $t("common.button.save") }}
                  <span class="svg-icon svg-icon-3 ms-2 me-0">
                    <inline-svg src="icons/duotune/arrows/arr064.svg" />
                  </span>
                </span>
                <span v-if="loading" class="indicator-progress">
                  {{ $t("common.button.loader") }}
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
              <!--end::Button-->
            </div>
            <!--end::Actions-->
          </Form>
          <!--end:Form-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - New Target-->
</template>

<style lang="scss">
.el-select {
  width: 100%;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { hideModal } from "@/presentation/helper/Dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import SwalNotification from "../plugins/SwalNotification";
import { VaccinationListModel } from "@/domain/vaccination/vaccinationList/model/VaccinationListModel";
import { CUSTOM_ERROR_MESSAGES, SWAL_MESSAGES } from "@/domain/Constant";
import { AnimalVaccineAddModel } from "@/domain/animal/animalVaccine/animalVaccineAdd/model/AnimalVaccineAddModel";

export default defineComponent({
  name: "new-target-modal",
  components: { Field, Form, ErrorMessage },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const vaccinationList = ref<VaccinationListModel[]>([]);
    const originalVaccinationList = ref<VaccinationListModel[]>([]);

    const swalNotification = new SwalNotification();

    const animalVaccinateModel = ref<AnimalVaccineAddModel>({
      vaccine: 0,
      amount: 0,
    });

    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const vaccinationController =
      store.state.ControllersModule.vaccinationController;
    const animalController = store.state.ControllersModule.animalController;

    if (!animalController) {
      throw new Error("AnimalController is not available in Vuex store.");
    }
    if (!vaccinationController) {
      throw new Error("VaccinationController is not available in Vuex store.");
    }

    //Create form validation object
    const form = Yup.object().shape({
      vaccine: Yup.string()
        .test(
          "isRequired",
          t(CUSTOM_ERROR_MESSAGES.required),
          function (value) {
            return value !== "0" && value !== null;
          }
        )
        .label("Vaccine"),
      amount: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("Amount"),
      description: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("Description"),
      date: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("Date"),
    });

    const getVaccinationList = () => {
      const vaccinationListModel: VaccinationListModel = {
        vaccine: {},
        page: 1,
        pageSize: pageSize.value,
      };

      vaccinationController
        .vaccinationList(vaccinationListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((vaccination) => {
              totalItems.value = vaccination.pagination.total;
              currentPage.value = vaccination.pagination.page;
              pageCount.value = vaccination.pagination.pageCount;
              vaccinationList.value.push(vaccination);
              originalVaccinationList.value.push(vaccination);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const submit = () => {
      loading.value = true;
      animalVaccinateModel.value.animal = Number(
        store.getters.selectedAnimalId
      );

      animalController
        .addAnimalVaccine(animalVaccinateModel.value)
        .then((response) => {
          loading.value = false;
          if (response.getValue()) {
            store.dispatch("newVaccine", store.getters.newVaccine + 1 );
            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS_ADD_ANIMAL_VACCINE),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              hideModal(newTargetModalRef.value);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(async () => {
      getVaccinationList();
    });

    return {
      animalVaccinateModel,
      submit,
      loading,
      formRef,
      newTargetModalRef,
      vaccinationList,
      form,
    };
  },
});
</script>
